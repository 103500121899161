import request from '@/utils/request'


// 新增接送人
export function addTransferAPI(data) {
    return request({
        url: '/icbc/addTransfer',
        method: 'post',
        data
    })
}

// 删除接送人  /organization/deleteTransfer/{id}
export function deleteTransferAPI(id) {
    return request({
        url: `/organization/deleteTransfer/${id}`,
        method: 'post',
    })
}


// /icbc/transfer/pagination  接送人列表
export function getTransferListAPI() {
    return request({
        url: '/icbc/transfer/pagination',
        method: 'get',
    })
}


//  /noCheckAvatar   上传接送人照片
export function noCheckAvatarAPI(data) {
    return request({
        url: '/upload/noCheckAvatar',
        method: 'post',
        timeout: 30000,
        data
    })
}

// 查询班级所有学生 /icbc/classes/searchStudents/{classId}
export function getStudentsListAPI(id) {
    return request({
        url: `/icbc/classes/searchStudents/${id}`,
        method: 'get',
    })
}


// 获取全部班级   /icbc/allClasses
export function getAllClassesListAPI() {
    return request({
        url: `/icbc/allClasses`,
        method: 'get',
    })
}

// /icbc/transferRecords  接送出入记录
export function getTransferRecordsListAPI(params) {
    return request({
        url: `/icbc/transferRecords/?userId=${params.userId}&time=${params.time}`,
        method: 'get',
        timeout: 30000
    })
}
