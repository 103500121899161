<template>
  <div class="inform">
    <div class="inform_top">
      <div class="left">
        <img :src="cancel" @click="toBack" alt />
      </div>
      <div class="center">添加接送人</div>

      <div class="right" @click="submitInfoFn">保存</div>
    </div>
    <div class="inform_center">
      <van-cell-group>
        <van-field
          clearable
          label="姓名"
          right-icon="cross-o"
          placeholder="请输入姓名"
          v-model="addUserData.name"
          @input="inputNameChangeFn"
          @click-right-icon="$toast('question')"
        />
        <van-field
          v-if="addUserData.type===0"
          label="学生姓名"
          :disabled="true"
          v-model="user.name"
          placeholder="请输入学生姓名"
        />
        <van-field
          v-if="addUserData.type===0"
          label="学生电话"
          :disabled="true"
          v-model="user.phone"
          placeholder="请输入学生电话"
        />
        <!-- <van-field type="password" label="与学生关系" placeholder="请选择与学生关系" /> -->
        <van-field
          readonly
          clickable
          label="与学生关系"
          :value="addUserData.relationship"
          placeholder="选择与学生关系"
          right-icon="arrow"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            title="与学生关系"
            :columns="columns"
            @cancel="onCancel"
            @confirm="onConfirm"
          />
        </van-popup>
        <van-field
          v-if="addUserData.type===1"
          label="有效期"
          :disabled="true"
          v-model="text"
          placeholder="请输入有效期"
        />
      </van-cell-group>
      <div class="bottom_upload" @click="toUploadFn">
        <div class="upload_in">
          <div>上传人脸照片</div>
          <img :src="right" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import right from "@/assets/right.png";
import { addTransferAPI } from "@/api/pick-up";
import { Toast } from "vant";
export default {
  data() {
    return {
      cancel,
      right,
      user: {},
      addUserData: {
        type: "",
        relationship: "",
        name: "",
        classId: "",
        picture: "",
      },
      showPicker: false,
      columns: ["爸爸", "妈妈", "爷爷", "奶奶", "外公", "外婆", "哥哥", "姐姐"],
      text: "5个自然日",
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("personInfo"));
    if (
      Number(this.$route.query.status) === 0 ||
      Number(this.$route.query.status) === 1
    ) {
      console.log(1);
      this.addUserData.type = Number(this.$route.query.status);
      this.addUserData.classId = this.user.classId;
    } else {
      this.addUserData = JSON.parse(this.$route.query.item);
    }
  },
  methods: {
    onConfirm(value, index) {
      this.addUserData.relationship = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    toBack() {
      this.$router.push({
        name: "PickInform",
      });
    },
    getDetailFn(id) {
      console.log(id);
    },
    submitInfoFn() {
      const { relationship, name, picture } = this.addUserData;
      if (!name) {
        Toast("请输入姓名!");
        return;
      } else if (!relationship) {
        Toast("请选择关系!");
        return;
      } else if (!picture) {
        Toast("请上传人脸照片!");
        return;
      }
      addTransferAPI(this.addUserData).then((res) => {
        if (res.code === 0) {
          Toast("保存成功!");
          this.$router.push({
            name: "PickInform",
          });
        }
      });
    },
    toUploadFn() {
      this.$router.push({
        name: "UploadFace",
        query: {
          item: JSON.stringify(this.addUserData),
        },
      });
    },
    inputNameChangeFn() {
      this.addUserData.name = this.addUserData.name.replace(
        /[`~!#$%^&*@\\ \/ , '()_\+=<>?:"[\];.{}|~！#￥%……&*（）={}|《》？：“”【】、；‘’，。、\s+]/g,
        ""
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.inform {
  .inform_top {
    height: 48px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #ddd;
    .left {
      position: absolute;
      left: 1rem;
      img {
        // width: 35px;
        height: 30px;
      }
    }
    .right {
      position: absolute;
      right: 1rem;
    }
  }
  .inform_center {
    margin-top: 10px;
    .bottom_upload {
      background: #fff;
      .upload_in {
        display: flex;
        padding: 0 1rem;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9rem;
        height: 43px;
        color: rgba(100, 101, 102);
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}
</style>